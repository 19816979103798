import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/index/indexBg.png';
const _hoisted_1 = {
  class: "banner-box"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "notTops"
};
const _hoisted_4 = {
  class: "home"
};
const _hoisted_5 = {
  class: "content acea-row row-between-wrapper"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "home-item__left"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  key: 0,
  class: "coin-name"
};
const _hoisted_11 = {
  key: 1,
  class: "coin-name"
};
const _hoisted_12 = {
  key: 0
};
const _hoisted_13 = {
  class: "home-item__right"
};
const _hoisted_14 = {
  class: "home-item__price"
};
import { IMG_URL } from '@/config';
import { homePair, getBanner, getNotices, getKings } from '@/http';
import Tabbar from '@/components/Tabbar/Tabbar.vue';
import { router } from '@/router';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { showToast } from 'vant';
import { useUserStore } from '@/stores/user';
import LineChart from '@/components/LineChart/LineChart.vue';
import { useI18n } from "vue-i18n";
export default {
  __name: 'home',
  setup(__props, {
    expose: __expose
  }) {
    const pairList = ref([]);
    const {
      t
    } = useI18n();
    const bannerList = ref([]);
    const notices = ref('');
    const userStore = useUserStore();
    /**  @property{Array} tabList = [加密货币,外汇,金子,期货] */
    const tabList = computed(() => userStore.tabList);
    let tabActive = ref(1);
    const setTabs = async index => {
      /*    if (index>=2){
             showToast(t('StayTuned'))
              return false
          }*/
      const loading = showToast({
        message: '',
        type: 'loading',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
        className: 'homeLoading'
      });
      await initValEnd();
      tabActive.value = index;
      await _homePair();
      await initVal();
      loading.close();
    };
    let toBg = ref(false);
    const changes = e => {
      toBg.value = e;
    };
    const imgKey = computed(() => {
      return {
        'en_US': 'img_pc',
        'fr_FR': 'fr_FR_img_pc',
        'es_ES': 'es_ES_img_pc',
        'it_IT': 'it_IT_img_pc',
        'vi': 'vt_VT_img_pc',
        'en_TH': 'en_TH_img_pc'
      }[userStore.lang];
    });
    const _getNotices = () => {
      notices.value = '';
      getNotices().then(({
        data
      }) => {
        data.data.forEach(item => {
          notices.value += item[{
            'en_US': 'name',
            'fr_FR': 'fr_FR_name',
            'es_ES': 'es_ES_name',
            'it_IT': 'it_IT_name',
            'vi': 'vt_VT_name',
            'en_TH': 'en_TH_name'
          }[userStore.lang]] + '  ';
        });
      });
    };
    _getNotices();
    const toContract = item => {
      userStore.setSymbolData(item);
      router.push('/contract/' + item.id);
    };
    const jumpTo = path => {
      router.push(path);
    };
    const _homePair = () => {
      return new Promise((resolve, reject) => {
        homePair().then(({
          data
        }) => {
          /**
           * 数据过滤： 数字货币和 外汇。 期货
           * */

          let nodeData = [];
          nodeData = data.filter(item => item.category === tabActive.value);
          // if (tabActive.value === 0){
          //     // 过滤0 和 2
          //     nodeData = data.filter(item => item.category === 0 || item.category === 2 )
          // }else {
          //     // 过滤1 和 3
          //     nodeData = data.filter(item =>  item.category === 3)
          // }
          nodeData.map((item, index) => {
            nodeData[index].priceChangePercent = item.rise;
          });
          let otData = nodeData.map(item => {
            return item.code;
          }).join(',');
          getKings({
            code: otData,
            limit: 30
          }).then(res => {
            let data = res.data;
            nodeData.forEach((item, index) => {
              item.PriceData = data[item.code];
            });
            pairList.value = nodeData;
            resolve(nodeData);
          });
        });
      });
      // 查询产品列表
    };
    let intervalId = null;
    const initVal = () => {
      intervalId = setInterval(_homePair, 10000); // 10秒
    };
    const initValEnd = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    onMounted(() => {
      _homePair();
      initVal();
      // 清理定时器
      return () => clearInterval(intervalId);
    });
    onUnmounted(() => {
      clearInterval(intervalId);
    });
    const _getBanner = () => {
      getBanner({
        category: 0
      }).then(({
        data
      }) => {
        bannerList.value = data;
      });
    };
    _getBanner();
    const getCoinInfo = (symbol, index) => {
      fetch('https://api.binance.com/api/v3/ticker/tradingDay?symbol=' + symbol).then(res => {
        return res.json();
      }).then(data => {
        pairList.value[index].price = data.lastPrice;
        pairList.value[index].priceChangePercent = data.priceChangePercent;
      });
    };
    __expose({
      initValEnd,
      initVal
    });
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_sticky = _resolveComponent("van-sticky");
      return _openBlock(), _createElementBlock(_Fragment, null, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        class: "indexBgs",
        alt: ""
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        class: "banner-swipe",
        autoplay: 3000,
        "show-indicators": false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bannerList.value, item => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: item.id
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              class: "banner-img",
              src: _unref(IMG_URL) + item[imgKey.value],
              alt: ""
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_sticky, {
        onChange: changes
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(["home-tab", {
            'homeBgs': _unref(toBg)
          }])
        }, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["items", {
              'active': _unref(tabActive) === item.value
            }]),
            onClick: $event => setTabs(item.value),
            key: index
          }, _toDisplayString(_ctx.$t(item.name)), 11, _hoisted_6);
        }), 128))])], 2)]),
        _: 1
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pairList.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "home-item",
          key: item.id,
          onClick: $event => toContract(item)
        }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
          src: _unref(IMG_URL) + item.logo,
          alt: ""
        }, null, 8, _hoisted_9), _unref(tabActive) === 0 && item.category === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(item.name) + "/", 1), _cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "noColor"
        }, "USDT", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(item.name), 1)]))]), item.PriceData ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(LineChart, {
          symbol: item.code,
          interval: "1m",
          itemPrice: item.PriceData,
          color: Number(item.priceChangePercent) > 0 ? '#1EC173' : '#FF4D4D'
        }, null, 8, ["symbol", "itemPrice", "color"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.price)), 1), _createElementVNode("p", {
          class: _normalizeClass([Number(item.priceChangePercent) > 0 ? 'green-text' : 'red-text'])
        }, _toDisplayString(item.priceChangePercent) + "%", 3)])])], 8, _hoisted_7);
      }), 128))])])], 64);
    };
  }
};